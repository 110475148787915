import { DateTime, Interval } from 'luxon'
import { Box, Button, Pressable, Text, VStack } from 'native-base'
import { useContext, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import ExpandButtonSVG from '../assets/ExpandButtonSVG'
import SparkleSVG from '../assets/SparkleSVG'
import { AppContext } from '../context/AppContextProvider'
import { AuthContext } from '../context/AuthContextProvider'
import { OverlayState } from '../context/OverlayContextProvider'
import { GET_PROOF_OF_SERVICE_URLS } from '../graphql/getProofOfServiceUrls'
import {
  GetProofOfServiceUrlsQuery,
  GetProofOfServiceUrlsQueryVariables,
  Order,
  PackageEventOperations,
} from '../types/graphql'
import { Alert } from './Alert'
import { DeliveryAddress } from './DeliveryAddress'
import { OrderMap } from './Map/OrderMap'
import OrderInfoHeader from './OrderInfoHeader'
import PackageInfoWithCollapsibleEventLog from './PackageInfoWithCollapsibleEventLog'
import Support from './Support'

interface ISearchResultProps {
  order: Order
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBackToSearch: (e: any) => void
}

export const SearchResult = ({ order, onBackToSearch }: ISearchResultProps) => {
  const { toggleModal, toggleMapModal } = useContext(OverlayState)
  const { firebaseUser } = useContext(AuthContext)
  const { handleSetProofOfServicePhotos } = useContext(AppContext)
  const [queryProofOfServiceUrlsByPackageIds, proofOfServicePhotoUrls] = useLazyQuery<
    GetProofOfServiceUrlsQuery,
    GetProofOfServiceUrlsQueryVariables
  >(GET_PROOF_OF_SERVICE_URLS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  })
  // For now we are only displaying the first built in alert rather than using the alert code
  const hasAlert = !!order.alerts?.length

  useEffect(() => {
    const deliveredPackageIds =
      order?.packages
        .filter(p => p.eventLog[0]?.operation === PackageEventOperations.Delivered)
        // ignore lint rule as we only want to return packageIds within the interval
        // eslint-disable-next-line array-callback-return
        .filter(p => {
          const deliveredTimeStamp = p?.eventLog[0]?.timestamp
          const interval = Interval.fromDateTimes(
            DateTime.fromISO(deliveredTimeStamp).setZone((p.timeZone ?? order.timeZone) as string),
            DateTime.fromISO(deliveredTimeStamp)
              .setZone((p.timeZone ?? order.timeZone) as string)
              .plus({ days: 90, hours: 23, minutes: 59 })
          )
          const now = DateTime.now().setZone((p.timeZone ?? order.timeZone) as string)
          const todayWithinInterval = interval.contains(now)
          if (todayWithinInterval) {
            return p.packageId
          }
        }) || []
    if (deliveredPackageIds.length > 0) {
      const packageIds: string[] = deliveredPackageIds.map(p => p.packageId)
      queryProofOfServiceUrlsByPackageIds({ variables: { packageIds } })
    }
  }, [order, queryProofOfServiceUrlsByPackageIds])

  useEffect(() => {
    // set proof of delivery photos in AppContext for full screen map
    if (proofOfServicePhotoUrls?.data?.proofOfServicePhotoUrls?.length) {
      handleSetProofOfServicePhotos(proofOfServicePhotoUrls?.data?.proofOfServicePhotoUrls)
    }
  }, [proofOfServicePhotoUrls, handleSetProofOfServicePhotos])

  const handleAddInstructions = () => {
    toggleModal({ showModal: true, auth_flow: 'instructions' })
  }

  const handleEditAddress = () => {
    toggleModal({ showModal: true, auth_flow: 'edit_address' })
  }

  const handleMapExpandToggle = () => {
    if (firebaseUser || (order?.address?.street && order?.address?.name)) {
      toggleMapModal()
    } else {
      toggleModal({ showModal: true, auth_flow: 'general' })
    }
  }

  return (
    <Box>
      {hasAlert && (
        <Box mb={3}>
          <Alert marginTop={0} />
        </Box>
      )}
      {order?.packages?.map((pkg, idx) => {
        return (
          <Box mt={idx > 0 ? 2 : 0}>
            <OrderInfoHeader trackingId={pkg.trackingId} />
            <PackageInfoWithCollapsibleEventLog
              pkg={pkg}
              showBottomBorder={idx === 0 && order?.packages?.length > 1}
              proofOfServicePhotos={proofOfServicePhotoUrls?.data?.proofOfServicePhotoUrls}
              proofOfServicePhotoUrlsCalled={proofOfServicePhotoUrls?.data !== undefined}
            />
          </Box>
        )
      })}
      <VStack alignItems={'center'}>
        <OrderMap
          order={order}
          toggleMap={handleMapExpandToggle}
          proofOfServicePhotos={proofOfServicePhotoUrls?.data?.proofOfServicePhotoUrls}
          userAuthed={!!firebaseUser}
        />
        {(firebaseUser || (order?.address?.street && order?.address?.name)) && (
          <Box zIndex={1000} style={{ position: 'absolute', right: 18, top: 18 }}>
            <Pressable onPress={handleMapExpandToggle}>
              <ExpandButtonSVG />
            </Pressable>
          </Box>
        )}
        <Box display={'flex'} flexDir={'column'} alignItems={'center'}>
          <DeliveryAddress
            order={order}
            editAddress={handleEditAddress}
            addInstructions={handleAddInstructions}
            styleProps={{ minWidth: '350px' }}
          />
          <Box mt={4}>
            <Support showMobile={false} maxWidth="350px" maxHeight="160px" />
          </Box>
        </Box>
        <Box mt={4}>
          <Button
            onPress={onBackToSearch}
            size="sm"
            width="250px"
            height="32px"
            borderRadius={100}
            bgColor="#ECECE5"
            leftIcon={<SparkleSVG />}
          >
            <Text color="#293042" fontWeight={500} fontSize={16}>
              Track Another Package
            </Text>
          </Button>
        </Box>
      </VStack>
    </Box>
  )
}
