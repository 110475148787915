import { useField, useFormikContext } from 'formik'
import { Pressable, Text, VStack } from 'native-base'
import { FormControl, IInputProps, Input } from 'native-base'
import React, { useCallback, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { logError } from '../../../../utils/monitorClient'
import { colors } from '../../../../utils/theme/configureTheme'

export interface IAddressReturnValue {
  street_line?: string
  secondary?: string
  city?: string
  state?: string
  zipcode?: string
}

export interface IAutofillTextInput extends IInputProps {
  label?: string
  leftIcon?: JSX.Element | JSX.Element[]
  placeholder?: string
  onPlaceSelect: (suggestion: IAddressReturnValue) => void
  optional?: boolean
  name: string
  clearable?: boolean
  handleBlur?: (val: string, val2: string) => void
  isDisabled?: boolean
  debounceDelay?: number
}

const DEFAULT_DEBOUNCE_WAIT = 500 //in millis

const AutofillTextInput = ({
  placeholder,
  onPlaceSelect,
  label,
  leftIcon,
  isDisabled,
  optional = false,
  name,
  clearable,
  handleBlur,
  debounceDelay,
  ...props
}: IAutofillTextInput) => {
  const [, meta, helpers] = useField<string>(name)
  const { isSubmitting } = useFormikContext()

  // eslint-disable-next-line
  let inputRef = useRef<any | null>(null)
  const [suggestionsAvailable, setSuggestionsAvailable] = useState(false)
  const [searchValue, setSearchValue] = useState(meta.value)
  const [suggestions, setSuggestions] = useState([])

  const getSuggestions = useCallback(async (inputText: string) => {
    if (inputText?.length) {
      try {
        const embeddedKey = process.env.REACT_APP_SMARTY_EMBEDDED_KEY
        const endpoint = `https://us-autocomplete-pro.api.smarty.com/lookup?search=${inputText}&key=${embeddedKey}&max_results=3`
        const response = await fetch(endpoint, {
          headers: { Referer: 'www.shipveho.com' },
        })
        const results = await response.json()
        if (results?.suggestions?.length) {
          setSuggestions(results?.suggestions)
        }
      } catch (err) {
        logError('Error fetching data for autocomplete', { error: err })
      }
    }
  }, [])

  const debounceGetSuggestions = useDebouncedCallback((textVal: string) => {
    getSuggestions(textVal)
  }, debounceDelay ?? DEFAULT_DEBOUNCE_WAIT)

  const debouncedOnChangeText = useCallback(
    (inputVal: string) => {
      setSearchValue(inputVal)
      debounceGetSuggestions(inputVal)
      // set the value for formik field regardless of suggestion result
      helpers.setValue(inputVal)
    },
    [debounceGetSuggestions, helpers]
  )

  const handleSuggestionOnPress = useCallback(
    (suggestion: IAddressReturnValue) => {
      if (suggestion.street_line) {
        setSearchValue(suggestion.street_line)
        helpers.setValue(suggestion.street_line)
        onPlaceSelect(suggestion)
        setSuggestions([])
      }
    },
    [helpers, onPlaceSelect]
  )

  return (
    <FormControl
      isInvalid={meta.error?.length ? true : false}
      testID={`${name}-form-control`}
      bg={'transparent'}
      style={{ width: '100%' }}
    >
      <VStack direction="row">
        {label && (
          <FormControl.Label
            testID={`${name}-label`}
            _text={{
              fontWeight: 600,
              fontSize: 13,
              color: colors.brand.softBlack,
            }}
          >
            {label}
          </FormControl.Label>
        )}
        {optional && (
          <FormControl.Label
            testID={`${name}-optional-label`}
            ml={3}
            _text={{
              fontWeight: 700,
              fontSize: 11,
              color: isDisabled ? colors.gray[200] : colors.brand.darkGrey,
              alignSelf: 'center',
              textTransform: 'uppercase',
            }}
          >
            Optional
          </FormControl.Label>
        )}
      </VStack>
      <Input
        width="full"
        bg={colors.brand.white}
        _focus={{
          bg: colors.brand.white,
          borderColor: colors.brand.softBlack,
        }}
        ref={inputRef}
        size="lg"
        isDisabled={isDisabled || isSubmitting}
        onFocus={() => setSuggestionsAvailable(true)}
        // need to stop onBlur event prop to allow onPress for suggestions to fire first
        // eslint-disable-next-line
        onBlur={(event: any) => {
          setTimeout(() => {
            event?.stopPropagation()
            setSuggestionsAvailable(false)
          }, 200)
        }}
        InputLeftElement={leftIcon}
        onChangeText={(val: string) => debouncedOnChangeText(val as string)}
        borderColor={colors.brand.darkGrey}
        borderWidth={2}
        value={searchValue}
        testID={`${name}-text-input`}
        {...props}
        isInvalid={!!meta.error}
        placeholder={placeholder ?? meta.value}
        autocomplete={'address-line1'}
      />
      {meta.error && (
        <FormControl.ErrorMessage fontSize={12} testID={`${name}-error`}>
          {meta.error}
        </FormControl.ErrorMessage>
      )}
      {suggestions?.length > 0 && searchValue && suggestionsAvailable && (
        <VStack
          style={{
            backgroundColor: colors.brand.lightGrey,
            borderRadius: 4,
            padding: 4,
            marginTop: 4,
            alignItems: 'center',
            paddingHorizontal: '20px',
            paddingTop: '10px',
            width: '110%',
            alignSelf: 'center',
          }}
        >
          {suggestions.map((suggestion: IAddressReturnValue, index) => {
            return (
              <Pressable
                key={index}
                onPress={() => handleSuggestionOnPress(suggestion)}
                style={{ backgroundColor: 'white', alignSelf: 'stretch' }}
              >
                <VStack px={'10px'} borderBottomWidth={'1px'} borderBottomColor={colors.brand.lightGrey}>
                  <Text>{`${suggestion?.street_line} ${suggestion?.secondary}`}</Text>
                  <Text>{`${suggestion?.city}, ${suggestion?.state} ${suggestion?.zipcode}`}</Text>
                </VStack>
              </Pressable>
            )
          })}
          {clearable && (
            <Pressable
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => setSuggestions([])}
            >
              <Text>Clear</Text>
            </Pressable>
          )}
        </VStack>
      )}
    </FormControl>
  )
}

export default AutofillTextInput
