import { ConfirmationResult, UserCredential } from 'firebase/auth'
import { Formik, FormikHelpers } from 'formik'
import { Modal, VStack, useToast } from 'native-base'
import React, { useCallback, useContext, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { useGateValue } from '@statsig/react-bindings'

import client from '../../apolloClient'
import { AppContext } from '../../context/AppContextProvider'
import { AuthContext } from '../../context/AuthContextProvider'
import { AGREE_TO_TERMS_AND_CONDITIONS } from '../../graphql/agreeToTermsAndConditions'
import { EDIT_ORDER } from '../../graphql/editOrder'
import { UPDATE_CLIENT_ADDRESS_INSTRUCTIONS_FOR_ORDER } from '../../graphql/updateClientAddressInstructionsForOrder'
import { VERIFY_PHONE_RELATED_TO_ORDER } from '../../graphql/verifyPhoneRelatedToOrder'
import { AddressAttribute, NewAddressInput, Order, OrderServiceType, PropertyType } from '../../types/graphql'
import { trackEvent } from '../../utils/analytics-v2'
import { STATSIG_FEATURE_GATES } from '../../utils/enums'
import { signInWithPhoneNumber } from '../../utils/firebase'
import { logError } from '../../utils/monitorClient'
import { ToastAlert } from './Common/ToastAlert'
import { ErrorModalContent } from './Error/ErrorModalContent'
import { PhoneNumberMismatchModalContent } from './Error/PhoneNumberMismatchModalContent'
import { AddressInputGroupType, addressFieldsSchema } from './Form/AddressForm'
import { AddressFormModalContent } from './Form/AddressFormModalContent'
import { InstructionsFormType, instructionsFormSchema } from './Form/InstructionsForm'
import { InstructionsFormModalContent } from './Form/InstructionsFormModalContent'
import { PREFERRED_LOCATIONS } from './Form/preferredLocations'
import { PhoneFormType, phoneFormSchema } from './Phone/PhoneInput'
import { PhoneModalContent } from './Phone/PhoneModalContent'
import { SubmittingModalContent } from './Submitting/SubmittingModalContent'
import { VerificationFormType, verificationFormSchema } from './Verification/VerificationInput'
import { VerificationModalContent } from './Verification/VerificationModalContent'

const normalizePhone = (phoneNumberString: string) => {
  // TO DO: Add normalization function based on region
  return '+' + phoneNumberString.replace(/[^\d]/g, '')
}

export enum Steps {
  Instructions = 0,
  Phone = 1,
  Verification = 2,
  Submitting = 3,
  PhoneNumberNotMatched = 4,
  Error = 5,
  EditAddress = 6,
}

const steps = [
  { name: 'Instructions', validationSchema: instructionsFormSchema, validateOnChange: true },
  { name: 'Phone', validationSchema: phoneFormSchema, validateOnChange: false },
  { name: 'Verification', validationSchema: verificationFormSchema, validateOnChange: false },
  { name: 'Submitting', validationSchema: null, validateOnChange: false },
  { name: 'PhoneNumberNotMatched', validationSchema: null, validateOnChange: false },
  { name: 'Error', validationSchema: null, validateOnChange: false },
  { name: 'EditAddress', validationSchema: null, validateOnChange: true },
]

export type StepContentProps = {
  step: number
  setStep: (step: number) => void | React.Dispatch<React.SetStateAction<number>>
  order: Order
  closeModal: () => void
  resendAuth: () => void
  resendLoading: boolean
  disableSubmit: boolean
  disableResend: boolean
  phoneModalProps?: ModalCustomProps
  verificationModalProps?: ModalCustomProps
  termsAndConditionsAction?: string
  submitModalProps?: ModalCustomProps
  authLoading?: boolean
}

/**
 * Render the main content of the modal based on the current step.
 * @param step current step of the instructions update process.
 * @returns Modal content based on the current step.
 */
const StepContent = ({
  step,
  setStep,
  order,
  closeModal,
  resendAuth,
  resendLoading,
  disableResend,
  disableSubmit,
  phoneModalProps,
  verificationModalProps,
  termsAndConditionsAction,
  submitModalProps,
}: StepContentProps) => {
  switch (step) {
    case Steps.Instructions:
      return <InstructionsFormModalContent order={order} closeModal={closeModal} />
    case Steps.Phone:
      return (
        <PhoneModalContent
          closeModal={closeModal}
          termsAndConditionsAction={termsAndConditionsAction}
          {...phoneModalProps}
        />
      )
    case Steps.Verification:
      return (
        <VerificationModalContent
          closeModal={closeModal}
          resendAuth={resendAuth}
          resendLoading={resendLoading}
          disableSubmit={disableSubmit}
          disableResend={disableResend}
          {...verificationModalProps}
        />
      )
    case Steps.Submitting:
      return <SubmittingModalContent {...submitModalProps} />
    case Steps.PhoneNumberNotMatched:
      return <PhoneNumberMismatchModalContent closeModal={closeModal} onSubmit={() => setStep(Steps.Phone)} />
    case Steps.Error:
      return <ErrorModalContent closeModal={closeModal} onSubmit={() => setStep(Steps.Phone)} />
    case Steps.EditAddress:
      return <AddressFormModalContent order={order} closeModal={closeModal} />
    default:
      return <div>Not Found</div>
  }
}

export type ModalFormType = InstructionsFormType & PhoneFormType & VerificationFormType & AddressInputGroupType

export type InstructionsModalProps = {
  order: Order
  isOpen: boolean
  setIsOpen: (step: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>
  activeStep: number
  setActiveStep: (step: number) => void | React.Dispatch<React.SetStateAction<number>>
  phoneModalProps?: ModalCustomProps
  verificationModalProps?: ModalCustomProps
  termsAndConditionsAction?: string
  submitModalProps?: ModalCustomProps
  auth_flow?: 'instructions' | 'edit_address' | 'general'
}

type ModalCustomProps = {
  modalHeaderText?: string
  modalBodyText?: string
  cancelText?: string
}

export const sanitizeEditAddressInput = (input: Partial<ModalFormType>, serviceType: OrderServiceType) => {
  const { phoneNumber, acceptedTerms, confirmationCode, ...editAddressInputFields } = input

  return {
    ...editAddressInputFields,
    ...(serviceType === OrderServiceType.Pickup
      ? {
          pickupInstructions: input.pickupInstructions ?? '',
          instructions: undefined,
        }
      : {
          instructions: input.instructions ?? '',
          pickupInstructions: undefined,
        }),
    securityCode:
      input.attributes?.find((attr: AddressAttribute) => attr === AddressAttribute.UseCodeToAccess) ||
      input?.securityCode
        ? input.securityCode
        : '',
  }
}

export default function InstructionsModal({
  order,
  isOpen,
  setIsOpen,
  activeStep,
  setActiveStep,
  phoneModalProps,
  verificationModalProps,
  termsAndConditionsAction,
  submitModalProps,
  auth_flow = 'general',
}: InstructionsModalProps) {
  const toast = useToast()
  const { setOrder } = useContext(AppContext)
  const { firebaseUser } = useContext(AuthContext)

  // Setup the phone validation
  const [recaptchaWrapperRef, setRecaptchaWrapperRef] = useState<HTMLDivElement | null>(null)
  const [resendLoading, setResendLoading] = useState(false)
  const [normalizedPhoneNumber, setNormalizedPhoneNumber] = useState<string | null>(null)
  const [submitVerifyPhoneLoading, setSubmitVerifyPhoneLoading] = useState(false)
  const [confirmation, setConfirmation] = useState<ConfirmationResult>()
  const [verifyAttemptCount, setVerifyAttemptCount] = useState(0)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [disableResend, setDisableResend] = useState(false)
  const showNewMapUI = useGateValue(STATSIG_FEATURE_GATES.showNewMapUI)

  const [verifyPhoneRelatedToOrder] = useLazyQuery<{
    isOrderAssociatedWithPhoneNumber: boolean
  }>(VERIFY_PHONE_RELATED_TO_ORDER, {
    fetchPolicy: 'network-only',
  })
  const [agreedToTermsAndConditions] = useMutation(AGREE_TO_TERMS_AND_CONDITIONS)
  const [updateInstructions] = useMutation(UPDATE_CLIENT_ADDRESS_INSTRUCTIONS_FOR_ORDER, {
    onCompleted: () => {
      // Submission successful, which means we have verified the phone number and that the phone number matches the order
      // Submit the terms and conditions agreement that was required to submit the instructions
      // TODO: should we do this earlier in the process?
      //   My thinking was to avoid a race condition with updating the instructions,
      //   and also to avoid adding more latency to the instructions update process
      agreedToTermsAndConditions()
      // Refetch all queries to update the UI
      client.refetchQueries({ include: 'all' })
      // Close the modal
      setIsOpen(false)
      // Show a success toast
      trackEvent('add_instructions_success', { ...(showNewMapUI && { features: 'cwa_map_view' }) })
      toast.show({
        placement: 'top',
        render: ({ id }) => (
          <ToastAlert
            id={id}
            title={'Instructions updated!'}
            description={'Added your instructions to this delivery'}
            status={'success'}
            variant={'subtle'}
            isClosable={false}
            closeToast={toast.close}
          />
        ),
      })
    },
    onError: error => {
      if (error.message === 'Authorized consumer does not match order consumer') {
        // Submission failed due to phone number not matching
        trackEvent('add_instructions_error', {
          error_reason: 'mismatched_phone',
          ...(showNewMapUI && { features: 'cwa_map_view' }),
        })
        setActiveStep(Steps.PhoneNumberNotMatched)
      } else {
        // Submission failed due to an unknown error
        trackEvent('add_instructions_error', {
          error_reason: 'unknown',
          ...(showNewMapUI && { features: 'cwa_map_view' }),
        })
        setActiveStep(Steps.Error)
      }
    },
  })
  const [editOrder] = useMutation(EDIT_ORDER, {
    onCompleted: data => {
      if (data?.editOrder?.address) {
        setOrder(data?.editOrder as Order)
        toast.show({
          placement: 'top',
          render: ({ id }) => (
            <ToastAlert
              id={id}
              title={'Address updated!'}
              description={'Updated your address for this delivery'}
              status={'success'}
              variant={'subtle'}
              isClosable={false}
              closeToast={toast.close}
            />
          ),
        })
      }
      // Refetch all queries to update the UI
      client.refetchQueries({ include: 'all' })
      // Close the modal
      setIsOpen(false)
      // Show a success toast
      trackEvent('edit_address_success', { features: 'cwa_map_view' })
    },
    onError: error => {
      logError(error?.message, { error })
      trackEvent('edit_address_error', { features: 'cwa_map_view', error_reason: 'unknown' })
      setActiveStep(Steps.Error)
    },
  })

  /**
   * Handle the submission of the phone number form. Normalizes the phone number and sends it to Firebase for verification.
   */
  const onPhoneFormSubmit = useCallback(
    async ({ phoneNumber, setError }: { phoneNumber: string; setError: (msg: string) => void }) => {
      try {
        trackEvent('get_authentication_code', {
          auth_flow: auth_flow,
          ...(showNewMapUI && { features: 'cwa_map_view' }),
        })
        const normalizedPhone = normalizePhone(phoneNumber)

        if (recaptchaWrapperRef) {
          // Save the normalized phone number incase we need to resend the verification code
          setNormalizedPhoneNumber(normalizedPhone)
          const verifyPhoneData = await verifyPhoneRelatedToOrder({
            variables: {
              orderId: order?.orderId,
              phone: normalizedPhone,
            },
          })

          if (!verifyPhoneData.data?.isOrderAssociatedWithPhoneNumber) {
            trackEvent('add_instructions_error', {
              error_reason: 'mismatched_phone',
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
            setActiveStep(Steps.PhoneNumberNotMatched)
            return false
          }
          // Firebase auth requires phone number in format +1 5557770160
          const firebaseConfirmation = await signInWithPhoneNumber(normalizedPhone, recaptchaWrapperRef)
          if (firebaseConfirmation) {
            trackEvent('get_authentication_code_success', {
              auth_flow: auth_flow,
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
            setConfirmation(firebaseConfirmation)
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (e.message.includes('reCAPTCHA')) {
            trackEvent('get_authentication_code_error', {
              auth_flow: auth_flow,
              error_reason: 'verification_failed',
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
            setError('Phone verification failed. Please reload the page and try again.')
          } else {
            trackEvent('get_authentication_code_error', {
              auth_flow: auth_flow,
              error_reason: 'unknown',
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
            setError('An error occurred. Please try again.')
          }
        }
        return false
      }
      return true
    },
    [recaptchaWrapperRef, order?.orderId, setActiveStep, verifyPhoneRelatedToOrder, auth_flow, showNewMapUI]
  )

  /**
   * Handle the submission of the verification code form. Sends the code to Firebase for verification.
   * If the code is correct, the phone number is verified and the page will be reloaded with the authenticated user.
   * If the code is incorrect, the user will be prompted to try again.
   */
  const onVerifyPhoneSubmit = useCallback(
    async ({ values, setError }: { values: Partial<ModalFormType>; setError: (msg: string) => void }) => {
      const {
        confirmationCode,
        type,
        attributes,
        instructions,
        preferredLocation,
        pickupInstructions,
        preferredPickupLocation,
        securityCode,
      } = values
      if (confirmation) {
        trackEvent('submit_verification_code', {
          auth_flow: auth_flow,
          ...(showNewMapUI && { features: 'cwa_map_view' }),
        })
        setSubmitVerifyPhoneLoading(true)

        let confirmRes: UserCredential
        try {
          confirmRes = await confirmation.confirm(confirmationCode!)
          if (confirmRes) {
            trackEvent('submit_verification_code_success', {
              auth_flow: auth_flow,
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })

            if (auth_flow === 'instructions') {
              updateInstructions({
                errorPolicy: 'none',
                notifyOnNetworkStatusChange: true,
                variables: {
                  orderId: order?.orderId,
                  updateInstructionsInput: {
                    attributes,
                    instructions: instructions == null ? undefined : instructions,
                    pickupInstructions: instructions == null ? undefined : pickupInstructions,
                    preferredLocation: preferredLocation === PREFERRED_LOCATIONS.NotSet ? undefined : preferredLocation,
                    preferredPickupLocation,
                    securityCode,
                    type,
                  },
                },
              })
            } else {
              // refetch when not instructions to update order and agree to T&C
              client.refetchQueries({ include: 'all' })
              agreedToTermsAndConditions()
            }
          }
        } catch (e: unknown) {
          setSubmitVerifyPhoneLoading(false)
          setVerifyAttemptCount(verifyAttemptCount + 1)
          if (verifyAttemptCount >= 3) {
            setDisableSubmit(true)
            setError('Too many attempts. Please try again later.')
            trackEvent('submit_verification_code_error', {
              auth_flow: auth_flow,
              error_reason: 'too_many_attempts',
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
          } else {
            if (e instanceof Error) {
              if (e?.message?.includes('auth/invalid-verification-code')) {
                trackEvent('submit_verification_code_error', {
                  auth_flow: auth_flow,
                  error_reason: 'code_mismatch',
                  ...(showNewMapUI && { features: 'cwa_map_view' }),
                })
                setError('Verification code does not match.')
              } else if (e?.message?.includes('auth/code-expired')) {
                trackEvent('submit_verification_code_error', {
                  auth_flow: auth_flow,
                  error_reason: 'code_expired',
                  ...(showNewMapUI && { features: 'cwa_map_view' }),
                })
                setError('Verification code has expired.')
              } else {
                setError('An error occurred. Please try again.')
              }
            }
          }

          return false
        }
      }
      return true
    },
    [
      confirmation,
      verifyAttemptCount,
      setSubmitVerifyPhoneLoading,
      order,
      updateInstructions,
      auth_flow,
      agreedToTermsAndConditions,
      showNewMapUI,
    ]
  )

  /**
   * Resend the authentication code to the user's phone number.
   * Only allows resending once per session (due to a limitation with reloading the ReCAPTCHA widget).
   */
  const resendAuth = useCallback(async () => {
    if (!submitVerifyPhoneLoading && !disableResend) {
      setResendLoading(true)
      if (normalizedPhoneNumber && recaptchaWrapperRef) {
        try {
          trackEvent('get_authentication_code', { auth_flow: auth_flow })
          const firebaseConfirmation = await signInWithPhoneNumber(normalizedPhoneNumber, recaptchaWrapperRef)

          if (firebaseConfirmation) {
            trackEvent('get_authentication_code_success', {
              auth_flow: auth_flow,
              ...(showNewMapUI && { features: 'cwa_map_view' }),
            })
            setConfirmation(firebaseConfirmation)
          }
        } catch (e) {
          trackEvent('get_authentication_code_error', {
            auth_flow: auth_flow,
            error_reason: 'unknown',
            ...(showNewMapUI && { features: 'cwa_map_view' }),
          })
          // TODO: Do we want to display an error message?
        } finally {
          setDisableResend(true)
        }
      }
      setResendLoading(false)
    }
  }, [normalizedPhoneNumber, submitVerifyPhoneLoading, disableResend, recaptchaWrapperRef, auth_flow, showNewMapUI])

  const handleEditAddressSubmit = useCallback(
    async ({ values }: { values: Partial<ModalFormType> }) => {
      const updatedOrder: NewAddressInput = sanitizeEditAddressInput(values, order?.serviceType) as NewAddressInput
      try {
        editOrder({
          errorPolicy: 'none',
          notifyOnNetworkStatusChange: true,
          variables: {
            orderId: order?.orderId,
            addressInput: updatedOrder,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorDescription: string = error?.message?.includes('Order is past cut-off for address changes')
          ? "Sorry, we can't make changes to your delivery address after 6am the day of your delivery. You can still update your delivery instructions at any time!"
          : 'Sorry, something went wrong updating your delivery address.'

        await client.refetchQueries({ include: 'all' })
        toast.show({
          placement: 'top',
          render: ({ id }) => (
            <ToastAlert
              id={id}
              title={'Unable to Update Delivery Address'}
              description={errorDescription}
              status={'error'}
              variant={'subtle'}
              isClosable={false}
              closeToast={toast.close}
            />
          ),
        })
        return false
      }
      return true
    },
    [editOrder, order?.orderId, order?.serviceType, toast]
  )

  const handleEditInstructions = useCallback(
    ({ values }: { values: Partial<ModalFormType> }) => {
      const {
        type,
        attributes,
        instructions,
        preferredLocation,
        pickupInstructions,
        preferredPickupLocation,
        securityCode,
      } = values

      try {
        updateInstructions({
          errorPolicy: 'none',
          notifyOnNetworkStatusChange: true,
          variables: {
            orderId: order?.orderId,
            updateInstructionsInput: {
              attributes,
              instructions: instructions == null ? undefined : instructions,
              pickupInstructions: instructions == null ? undefined : pickupInstructions,
              preferredLocation: preferredLocation === PREFERRED_LOCATIONS.NotSet ? undefined : preferredLocation,
              preferredPickupLocation,
              securityCode,
              type,
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err?.message === 'Authorized consumer does not match order consumer') {
          // Submission failed due to phone number not matching
          trackEvent('add_instructions_error', {
            error_reason: 'mismatched_phone',
            ...(showNewMapUI && { features: 'cwa_map_view' }),
          })
          setActiveStep(Steps.PhoneNumberNotMatched)
        } else {
          // Submission failed due to an unknown error
          trackEvent('add_instructions_error', {
            error_reason: 'unknown',
            ...(showNewMapUI && { features: 'cwa_map_view' }),
          })
          setActiveStep(Steps.Error)
        }
      }
    },
    [order?.orderId, setActiveStep, updateInstructions, showNewMapUI]
  )

  // Setup the initial values for the form
  const { validationSchema, validateOnChange } = steps[activeStep]

  const filteredAttributes: AddressAttribute[] = order?.address?.attributes?.filter(Boolean).map(attr => attr!) ?? []
  const initialValues: Partial<ModalFormType> = {
    type: order?.address?.type ?? PropertyType.House,
    attributes: filteredAttributes,
    securityCode: order?.address?.securityCode ?? '',
    phoneNumber: '',
    acceptedTerms: false,
    confirmationCode: '',
    name: order?.address?.name ?? '',
    street: order?.address?.street ?? '',
    apartment: order?.address?.apartment ?? '',
    city: order?.address?.city ?? '',
    state: order?.address?.state ?? '',
    zipCode: order?.address?.zipCode ?? '',
  }
  // Since these two fields are optional and hidden by default, we want to have the concept of "NotSet".
  // If the user has never opened the other instructions section, they have not made any selections for these fields
  // so we should not update the values in the database.
  if (order?.serviceType === 'pickup') {
    initialValues.preferredPickupLocation = order?.address?.preferredPickupLocation ?? PREFERRED_LOCATIONS.NotSet
    initialValues.pickupInstructions = order?.address?.pickupInstructions ?? null
  } else {
    initialValues.preferredLocation = order?.address?.preferredLocation ?? PREFERRED_LOCATIONS.NotSet
    initialValues.instructions = order?.address?.instructions ?? null
  }
  /**
   * Close the instructions modal.
   */
  const _closeModal = () => {
    setIsOpen(false)
  }

  /**
   * Handle the submission of the form based on the current step.
   * Will do step-specific actions, then progress the submission flow forward if there are no errors.
   * @param values Form values.
   * @param formikHelpers Formik helpers functions.
   */
  const _handleSubmit = async (
    values: Partial<ModalFormType>,
    formikHelpers: FormikHelpers<Partial<ModalFormType>>
  ) => {
    let moveToNextStep = true
    switch (activeStep) {
      case Steps.Phone:
        moveToNextStep = await onPhoneFormSubmit({
          phoneNumber: values.phoneNumber!,
          setError: msg => formikHelpers.setErrors({ phoneNumber: msg }),
        })
        break
      case Steps.Verification:
        moveToNextStep = await onVerifyPhoneSubmit({
          values,
          setError: msg => formikHelpers.setErrors({ confirmationCode: msg }),
        })
        break
      case Steps.Instructions:
        trackEvent('add_instructions', { ...(showNewMapUI && { features: 'cwa_map_view' }) })
        break
      case Steps.EditAddress:
        moveToNextStep = await handleEditAddressSubmit({ values })
        break
      default:
        break
    }

    if (moveToNextStep) {
      if (auth_flow === 'edit_address' && activeStep === Steps.Verification) {
        // refetch to update address fields and close modal
        client.refetchQueries({ include: 'all' })
        setIsOpen(false)
      } else if (auth_flow === 'edit_address' && activeStep === Steps.EditAddress) {
        // set modal step to submit - handleEditAddressSubmit will close modal in onComplete
        setActiveStep(Steps.Submitting)
      } else if (auth_flow === 'general' && activeStep === Steps.Verification) {
        // general login flow should close modal after auth
        client.refetchQueries({ include: 'all' })
        setIsOpen(false)
      } else if (auth_flow === 'instructions' && (firebaseUser || (order?.address?.street && order?.address?.name))) {
        // if user is authed - directly call editInstructions mutation
        handleEditInstructions({ values })
        setActiveStep(Steps.Submitting)
      } else {
        setActiveStep(activeStep + 1)
      }
      formikHelpers.setTouched({})
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <VStack space={3} alignItems={'center'}>
      <Modal isOpen={isOpen} onClose={_closeModal} size={'xl'}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={
            activeStep === Steps.EditAddress ? addressFieldsSchema(order?.reroutableZipCodes) : validationSchema
          }
          validateOnMount={true}
          validateOnChange={validateOnChange}
          onSubmit={_handleSubmit}
        >
          <StepContent
            step={activeStep}
            setStep={setActiveStep}
            order={order}
            closeModal={_closeModal}
            resendAuth={resendAuth}
            resendLoading={resendLoading}
            disableSubmit={disableSubmit}
            disableResend={disableResend}
            phoneModalProps={phoneModalProps}
            verificationModalProps={verificationModalProps}
            termsAndConditionsAction={termsAndConditionsAction}
            submitModalProps={submitModalProps}
          />
        </Formik>
      </Modal>
      <div ref={ref => setRecaptchaWrapperRef(ref)}>
        <div id="recaptcha-container"></div>
      </div>
    </VStack>
  )
}
