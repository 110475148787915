import { DateTime } from 'luxon'
import { Center, Heading, Image, Modal, Pressable, Text, VStack } from 'native-base'
import { useContext, useEffect, useState } from 'react'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { Box } from '@mui/material'
import { MagnifyingGlassPlus } from '@phosphor-icons/react'

import { AppContext } from '../context/AppContextProvider'
import { EventLog, Order, Package, PackageEventOperations, ProofOfServicePhotoUrl } from '../types/graphql'
import { sortedList } from '../utils/displayUtils'
import { packageHadDeliveryIssueHeader, packageHadDeliveryIssueSubheader } from '../utils/helper'
import { ProofOfServicePhoto } from './Map/OrderMap'
import { PackageEvent } from './PackageEvent'

export default function PackageInfoWithCollapsibleEventLog({
  pkg,
  showBottomBorder = false,
  proofOfServicePhotos,
  proofOfServicePhotoUrlsCalled = false,
}: {
  pkg: Package
  showBottomBorder?: boolean
  proofOfServicePhotos?: ProofOfServicePhoto[]
  proofOfServicePhotoUrlsCalled?: boolean
}) {
  const { order } = useContext(AppContext)

  return (
    <Box style={{ borderBottom: showBottomBorder ? '1px solid #C3C3BE' : '' }}>
      <PackageStatusHeader
        pkg={pkg}
        order={order as Order}
        clientDisplayName={order?.clientDisplayName as string}
        deliveryDate={order?.deliveryDate as string}
        _proofOfServicePhotoUrls={proofOfServicePhotos}
        _proofOfServicePhotoUrlsCalled={proofOfServicePhotoUrlsCalled}
      />
      <CollapsiblePackageEventLog
        _packageDetails={pkg}
        _clientDisplayName={order?.clientDisplayName as string}
        _serviceType={order?.serviceType as string}
        key={pkg.trackingId}
      />
    </Box>
  )
}

const PackageStatusHeader = ({
  pkg,
  clientDisplayName,
  deliveryDate,
  _proofOfServicePhotoUrls,
  _proofOfServicePhotoUrlsCalled,
  order,
}: {
  pkg: Package
  order: Order
  clientDisplayName: string
  deliveryDate: string
  _proofOfServicePhotoUrls?: ProofOfServicePhotoUrl[]
  _proofOfServicePhotoUrlsCalled: boolean
}) => {
  const formattedExpectedDeliveryDate = DateTime.fromISO(deliveryDate).toLocaleString(DateTime.DATE_MED)
  const isDelivered = pkg?.eventLog[0]?.operation === PackageEventOperations.Delivered
  const deliveredTimestamp =
    isDelivered &&
    DateTime.fromISO(pkg?.eventLog[0]?.timestamp)
      .setZone(pkg?.timeZone as string)
      .toLocaleString(DateTime.DATETIME_FULL)
  const packageUrl = _proofOfServicePhotoUrls?.find(packageUrl => packageUrl.packageId === pkg.packageId)

  const [photoModalVisible, setPhotoModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (photoModalVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [photoModalVisible])

  const headerFromPackageStatus = ({
    pkg,
    order,
    clientDisplayName,
    deliveryDate,
  }: {
    pkg: Package
    order: Order
    clientDisplayName: string
    deliveryDate: string | null | undefined
  }) => {
    const mostRecentPkgStatus = pkg?.eventLog[0]?.operation
    const getPkgExpectedDeliveryDate = ({ pkg, order }: { pkg: Package; order: Order }): string => {
      const { calculatedPackagesInformation } = order
      const { notDeliveredPackages } = calculatedPackagesInformation
      let { groupedByDeliveryDate } = notDeliveredPackages
      let deliveryString = ''

      groupedByDeliveryDate.forEach(_pkg => {
        if (_pkg?.trackingIds.includes(pkg.trackingId)) {
          deliveryString = DateTime.fromISO(_pkg.deliveryDate)
            .setZone(pkg?.timeZone as string)
            .toLocaleString(DateTime.DATE_MED)
        }
      })
      return `Expected Delivery on ${deliveryString}`
    }

    switch (mostRecentPkgStatus) {
      case PackageEventOperations.Created:
        return {
          header: deliveryDate ? getPkgExpectedDeliveryDate({ pkg, order }) : 'Order Information Received',
          subheader: deliveryDate ? null : 'Awaiting expected delivery date.',
        }
      case PackageEventOperations.DroppedOffAtVeho:
        return {
          header: deliveryDate ? `Expected Delivery on ${formattedExpectedDeliveryDate}` : 'Order Information Received',
          subheader: deliveryDate ? null : 'Awaiting expected delivery date.',
        }
      case PackageEventOperations.PickedUpFromVeho:
        return {
          header:
            'Out for delivery!' +
            (pkg?.deliveryTimeWindow?.startsAt && pkg?.deliveryTimeWindow?.endsAt
              ? ` Arriving between ${DateTime.fromISO(pkg.deliveryTimeWindow?.startsAt)
                  .setZone(pkg?.timeZone as string)
                  .toLocaleString(DateTime.TIME_SIMPLE)} and ${DateTime.fromISO(pkg.deliveryTimeWindow?.endsAt)
                  .setZone(pkg?.timeZone as string)
                  .toLocaleString(DateTime.TIME_SIMPLE)}`
              : ''),
          subheader: null,
        }
      case PackageEventOperations.Delivered:
        return {
          header: 'Delivered!',
          subheader: deliveredTimestamp,
        }
      case PackageEventOperations.Misdelivered:
        return {
          header: 'Issue with Delivery',
          subheader:
            'We apologize, something went wrong with this delivery. Delivery may be reattempted today or tomorrow. Contact Support with questions.',
        }
      case PackageEventOperations.PackageHadDeliveryIssue:
        return {
          header: packageHadDeliveryIssueHeader(pkg?.eventLog[0]?.message as string),
          subheader: packageHadDeliveryIssueSubheader(pkg?.eventLog[0]?.message as string),
        }
      case PackageEventOperations.Discarded:
        return {
          header: 'Delivery Cancelled',
          subheader: `Your package from ${clientDisplayName} was unable to be delivered. We have let ${clientDisplayName} know. If you have questions or need help, please text Veho Customer Support at 68953.`,
        }
      case PackageEventOperations.ReturnedToClient:
        return {
          header: 'Delivery Cancelled',
          subheader: `Your order is being returned to ${clientDisplayName} after multiple unsuccessful delivery attempts.`,
        }
      case PackageEventOperations.Cancelled:
        return {
          header: 'Delivery Cancelled',
          subheader: 'This order has been cancelled.',
        }
      default:
        break
    }
  }

  return (
    <VStack mb={3} px={2}>
      <Center>
        <Modal
          backgroundColor="rgba(0, 0, 0, 0.0)"
          isOpen={photoModalVisible}
          onClose={() => setPhotoModalVisible(false)}
          avoidKeyboard
        >
          <Modal.Content backgroundColor="rgba(0, 0, 0, 0.0)" style={{ shadowOpacity: 0 }} width={'100%'}>
            <Modal.Body>
              <Image
                src={packageUrl?.url as string}
                borderRadius="5px"
                width={undefined}
                height={'100%'}
                style={{ aspectRatio: 3 / 4 }}
                alt={'Proof of Service Photo'}
              />
              <Pressable
                onPress={() => setPhotoModalVisible(!photoModalVisible)}
                style={{ position: 'absolute', top: 35, right: 33 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
                  <rect width={32} height={32} fill="#21211F" rx={16} />
                  <path
                    fill="#fff"
                    d="M21.03 19.97a.751.751 0 0 1-1.062 1.062L16 17.062l-3.97 3.969a.751.751 0 1 1-1.062-1.063L14.938 16l-3.969-3.97a.752.752 0 0 1 1.063-1.062L16 14.938l3.97-3.97a.751.751 0 1 1 1.062 1.062L17.062 16l3.969 3.97Z"
                  />
                </svg>
              </Pressable>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Center>
      {isDelivered &&
        // did we get a package URL from that call by package ID?
        packageUrl?.url && (
          <Center py={2}>
            <Pressable onPress={() => setPhotoModalVisible(!photoModalVisible)}>
              <Box>
                <Image
                  src={packageUrl.url}
                  size="sm"
                  mt={3}
                  borderRadius="4px"
                  width={255}
                  height={255}
                  resizeMode={'cover'}
                  style={{ overflow: 'hidden' }}
                  alt={'Proof of Service Photo'}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: '#21211F',
                    background: '#000000',
                    border: '1px solid #21211F',
                    borderRadius: 20,
                    width: 30,
                    height: 30,
                    boxSizing: 'border-box',
                    position: 'absolute',
                    top: '30px',
                    right: '10px',
                  }}
                >
                  <MagnifyingGlassPlus size={15} color="#FFFFFF" weight="bold" />
                </Box>
              </Box>
            </Pressable>
          </Center>
        )}
      <Heading style={{ fontSize: isMobile ? 28 : 32 }} fontFamily={'bodyBold'} fontWeight="700" lineHeight={32}>
        {headerFromPackageStatus({ pkg, order, clientDisplayName, deliveryDate })?.header}
      </Heading>
      <Text style={{ fontSize: isMobile ? 14 : 18 }} fontWeight="400" lineHeight={22}>
        {headerFromPackageStatus({ pkg, order, clientDisplayName, deliveryDate })?.subheader}
      </Text>
    </VStack>
  )
}

const CollapsiblePackageEventLog = ({
  _packageDetails,
  _clientDisplayName,
  _serviceType,
}: {
  _packageDetails: Package
  _clientDisplayName: string
  _serviceType: string
}) => {
  const [sortedDetails, setSortedDetails] = useState<EventLog[]>([])
  const [eventLogExpanded, setEventLogExpanded] = useState<boolean>(false)

  useEffect(() => {
    setSortedDetails(sortedList(_packageDetails))
  }, [_packageDetails])

  const handleToggleExpandEventLog = () => {
    setEventLogExpanded(!eventLogExpanded)
  }

  return (
    <>
      <Box key={_packageDetails.packageId} pl={1}>
        {!eventLogExpanded ? (
          <PackageEvent
            trackingId={_packageDetails.trackingId}
            details={sortedDetails[0]}
            serviceType={_serviceType}
            clientDisplayName={_clientDisplayName}
            showBorder={false}
            isMostCurrentEvent={true}
            onClick={() => handleToggleExpandEventLog()}
            packageEventLogExpanded={eventLogExpanded}
          />
        ) : (
          sortedDetails.map((details, index) => (
            <PackageEvent
              trackingId={_packageDetails.trackingId}
              details={details}
              serviceType={_serviceType}
              clientDisplayName={_clientDisplayName}
              showBorder={sortedDetails.length - 1 !== index}
              isMostCurrentEvent={index === 0}
              onClick={() => (index === 0 ? handleToggleExpandEventLog() : null)}
              packageEventLogExpanded={eventLogExpanded}
            />
          ))
        )}
      </Box>
    </>
  )
}
