import { ArrowBackIcon, Box, Button, Link, Text } from 'native-base'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { useGateValue } from '@statsig/react-bindings'

import { AppContext } from '../context/AppContextProvider'
import { AuthContext } from '../context/AuthContextProvider'
import { GET_ORDER_BY_TRACKING_ID } from '../graphql/getOrderByTrackingId'
import { Order } from '../types/graphql'
import { analytics, trackEvent } from '../utils/analytics-v2'
import { STATSIG_FEATURE_GATES } from '../utils/enums'
import { logError } from '../utils/monitorClient'
import { CMABanner } from './CMABanner'
import FAQ from './FAQ'
import Loader from './Loader'
import OrderInfo from './OrderInfo'
import PackageInfo from './PackageInfo'
import { SearchResult } from './SearchResult'

const SearchResultsPage = () => {
  const { trackingId } = useParams()
  const { order, setOrder, clearOrder } = useContext(AppContext)
  const { firebaseUser } = useContext(AuthContext)
  const showNewMapUI = useGateValue(STATSIG_FEATURE_GATES.showNewMapUI)
  const showWebSupportChatBot = useGateValue(STATSIG_FEATURE_GATES.webSupportChatBot)
  const { loading, error, data, refetch } = useQuery<{
    order: Order
  }>(GET_ORDER_BY_TRACKING_ID, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { trackingId },
  })
  const navigate = useNavigate()

  // GestureResponderEvent not exported by NativeBase
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBackToSearch = (e: any) => {
    e.preventDefault()
    trackEvent('return_to_tracking_page', { ...(showNewMapUI && { features: 'cwa_map_view' }) })
    clearOrder()
    navigate('/')
  }

  useEffect(() => {
    if (showNewMapUI) {
      analytics.page({ title: 'Tracker | Veho | New' })
    }
  }, [showNewMapUI])

  useEffect(() => {
    if (showWebSupportChatBot) {
      // @ts-ignore
      window.zE('messenger', 'show')
    }
  }, [showWebSupportChatBot])

  useEffect(() => {
    try {
      if (data) {
        if (firebaseUser) {
          // refetch order if authed to get additional fields
          refetch()
        }
        setOrder(data?.order)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logError('SearchResultsPage error encountered', { error: e })
    }
  }, [data, setOrder, firebaseUser, refetch])

  return (
    <>
      {loading && <Loader />}
      {error && !error?.message?.includes('Not Authorized') && (
        <Box style={{ margin: 'auto' }} display="flex" justifyContent="center" alignItems="flex-start">
          <Text style={{ color: '#7B0000', maxWidth: 400, margin: 20 }}>
            Looks like there was an issue fetching the tracking info. Please retry.
          </Text>
        </Box>
      )}
      {!loading && !error && data?.order === null && (
        <Box style={{ margin: 'auto' }} display="flex" justifyContent="center" alignItems="flex-start">
          <Text style={{ color: '#7B0000', maxWidth: 400, margin: 20 }}>
            We can't find a package associated with that number. Please double check your tracking ID or Barcode. If you
            need help, text <Link href="sms:68953">68953</Link> to contact support.
          </Text>
        </Box>
      )}
      {!loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="stretch"
          alignContent="stretch"
          flexDirection="row"
          px={3}
          pt={showNewMapUI ? 1 : 4}
          pb={4}
          width="100%"
        >
          <div id="searchResults">
            <Box>
              {!showNewMapUI && (
                <Box>
                  <Button
                    onPress={onBackToSearch}
                    size="sm"
                    width="212px"
                    height="32px"
                    borderRadius={100}
                    bgColor="#ECECE5"
                    leftIcon={<ArrowBackIcon style={{ fontSize: 14, color: '#293042' }} />}
                  >
                    <Text color="#293042" fontWeight={600} fontSize={14}>
                      Track Another Package
                    </Text>
                  </Button>
                </Box>
              )}
              {data?.order !== null &&
                order &&
                (showNewMapUI ? (
                  <SearchResult order={order} onBackToSearch={onBackToSearch} />
                ) : (
                  <Box>
                    <OrderInfo />
                    <PackageInfo />
                  </Box>
                ))}
            </Box>
          </div>
        </Box>
      )}
      <Box mt={5} width={'100%'}>
        <CMABanner />
      </Box>
      <FAQ />
    </>
  )
}

export default SearchResultsPage
