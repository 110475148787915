export enum BREADCRUMB_TYPES {
  error = 'error',
  info = 'info',
  log = 'log',
  manual = 'manual',
  navigation = 'navigation',
  request = 'request',
  user = 'user',
}

export enum UPDATE_DELIVERY_TYPES {
  reroute = 'reroute',
  update_instructions = 'update_instructions',
}

export enum UPDATE_DELIVERY_STAGES {
  initiated = 'initiated',
  canceled = 'canceled',
  completed = 'completed',
  go_back = 'go_back',
  next = 'next',
}

export enum EXTERNAL_LINK_TYPES {
  internal = 'internal',
  external = 'external',
}

export enum EXTERNAL_LINK_NAMES {
  view_terms = 'view_terms',
  view_privacy_policy = 'view_privacy_policy',
}

export enum STATSIG_FEATURE_GATES {
  showNewMapUI = 'cwa_display_new_map_ui',
  webSupportChatBot = 'web_support_chat_bot',
}
