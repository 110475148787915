import { Pressable } from 'native-base'
import { useCallback, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Box } from '@mui/material'
import { Map, MapCameraChangedEvent, MapCameraProps } from '@vis.gl/react-google-maps'

import CloseButtonSVG from '../../assets/CloseButtonSVG'
import { OverlayState } from '../../context/OverlayContextProvider'
import { Address, AddressImage, AddressImageType, Location, Order } from '../../types/graphql'
import { useScreenSize } from '../../utils/displayUtils'
import { logError } from '../../utils/monitorClient'
import { DeliveryAddress } from '../DeliveryAddress'
import MapMarker, { MarkerVariants } from './MapMarker'

export const MAP_DEFAULT_DELTA = 0.025
export const MAP_MIN_DELTA = 0.0025
export const MAP_ZIP_DELTA = 0.1
export const MAP_CITY_DELTA = 0.14
export const MAP_STATE_DELTA = 0.1

export interface ProofOfServicePhoto {
  packageId: string
  trackingId?: string
  url?: string | null
  location?: Location | null
}

interface OrderMapProps {
  order: Order
  zoomAnimate?: boolean
  proofOfServicePhotos?: ProofOfServicePhoto[]
  viewRatio?: number
  bottomPaddingRatio?: number
  orderMapStyles?: {
    orderMapContainer?: object
    mapContainer?: object
  }
  toggleMap?: () => void
  fullScreenMap?: boolean
  addInstructions?: () => void
  editAddress?: () => void
  userAuthed?: boolean
}

interface IMapMarkerProps {
  coordinate: {
    latitude: number
    longitude: number
  }
  _minLat?: number
  _minLng?: number
  _maxLat?: number
  _maxLng?: number
  key?: string
  zIndex?: number
  variant?: string
}

export const OrderMap = ({
  order,
  zoomAnimate = false,
  proofOfServicePhotos,
  bottomPaddingRatio,
  viewRatio = 1,
  orderMapStyles,
  toggleMap,
  fullScreenMap = false,
  addInstructions,
  editAddress,
  userAuthed,
}: OrderMapProps) => {
  const authedUserOwnsOrder: boolean = order?.authedUserOwnsOrder ?? false
  const address: Partial<Address> | undefined = order?.address ?? undefined
  const { toggleModal } = useContext(OverlayState)
  const screenSize = useScreenSize()
  const isMobileScreen = isMobile || screenSize.width < 1000
  const placementPhoto = authedUserOwnsOrder ? getPlacementPhoto(address) : undefined
  const markerPropsArray: IMapMarkerProps[] = useBuildMarkerPropsArray({
    order: order as Order,
    placementPhoto,
    proofOfServicePhotos,
    userAuthed,
  })
  // default map camera to middle of the US with zoom level set to show entire majority of the country
  const [cameraProps, setCameraProps] = useState<MapCameraProps>({
    center: { lat: 39.953214677115, lng: -98.60239691872582 },
    zoom: 4,
  })

  useEffect(() => {
    async function fetchInitialRegion() {
      const [latitude, longitude, latitudeDelta, longitudeDelta] = await calculateRegion(
        markerPropsArray,
        viewRatio,
        bottomPaddingRatio,
        address
      )
      if (latitude && longitude && latitudeDelta && longitudeDelta) {
        setCameraProps({
          center: { lat: latitude, lng: longitude },
          zoom: order?.address?.location
            ? latitudeDelta > MAP_MIN_DELTA && longitudeDelta > MAP_MIN_DELTA
              ? 16
              : 17
            : 10,
        })
      }
    }

    fetchInitialRegion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, userAuthed, proofOfServicePhotos])

  const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
    setCameraProps(ev.detail)
  }, [])

  const renderMapMarkers = () => {
    return markerPropsArray.map(marker => {
      const { latitude: lat, longitude: lng } = marker.coordinate
      return (
        <MapMarker
          position={{ lat, lng }}
          variant={marker.variant as MarkerVariants}
          onClick={fullScreenMap ? () => {} : toggleMap}
        />
      )
    })
  }

  const handleOnMapClick = useCallback(() => {
    if (!userAuthed) {
      if (order?.instructionEditsAllowed) {
        toggleModal({ showModal: true, auth_flow: 'general' })
      }
    } else {
      if (toggleMap) {
        toggleMap()
      }
    }
  }, [toggleMap, toggleModal, userAuthed, order?.instructionEditsAllowed])

  return (
    <Box
      style={{
        flex: 1,
        width: isMobileScreen ? '120%' : '100%',
        height: 228,
        ...orderMapStyles?.orderMapContainer,
      }}
    >
      <Map
        style={{
          width: '100%',
          height: '228px',
          borderRadius: '20px',
          overflow: 'hidden',
          ...orderMapStyles?.mapContainer,
        }}
        {...cameraProps}
        defaultCenter={{ lat: cameraProps.center.lat, lng: cameraProps.center.lng }}
        defaultZoom={10}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        onCameraChanged={handleCameraChange}
        onClick={handleOnMapClick}
      >
        {renderMapMarkers()}
      </Map>
      {fullScreenMap && (
        <Box style={{ position: 'absolute', top: 10, right: '-7%' }}>
          <Pressable onPress={toggleMap}>
            <CloseButtonSVG />
          </Pressable>
        </Box>
      )}
      {fullScreenMap && (
        <Box
          style={{
            position: 'absolute',
            bottom: 10,
            width: isMobileScreen ? (isMobile ? '85%' : '50%') : '50%',
            marginLeft: isMobileScreen ? (isMobile ? '7.5%' : 0) : 0,
            right: isMobile ? '0%' : '15%',
          }}
        >
          <DeliveryAddress order={order} addInstructions={addInstructions} editAddress={editAddress} />
        </Box>
      )}
    </Box>
  )
}

// Extract placement photo from address
const getPlacementPhoto = (address: Address) => {
  const addressImages: Record<string, AddressImage> | undefined = address.images?.reduce((addrImages, image) => {
    return { ...addrImages, [image?.imageType as AddressImageType]: image }
  }, {})
  return addressImages?.[AddressImageType.PlacementPhoto]
}

// Build all marker props for rendering later
const useBuildMarkerPropsArray = ({
  order,
  placementPhoto,
  proofOfServicePhotos,
  userAuthed = false,
}: {
  order: Order
  placementPhoto?: AddressImage
  proofOfServicePhotos?: ProofOfServicePhoto[]
  userAuthed?: boolean
}) => {
  const orderAddress = order?.address
  const { lat: addressLat, lng: addressLng } = orderAddress?.location ?? {}
  let markers: IMapMarkerProps[] = []

  if (addressLat && addressLng) {
    const adddressMarker = {
      key: `marker_address_${orderAddress?.addressId}`,
      coordinate: { latitude: addressLat, longitude: addressLng },
      zIndex: 10,
      variant: orderAddress?.type?.toLowerCase() as MarkerVariants,
    } as IMapMarkerProps

    markers.push(adddressMarker)
  }

  if (placementPhoto && placementPhoto?.location?.lat && placementPhoto?.location?.lng) {
    const placementPhotoMarker = {
      key: `marker_addressImage_${placementPhoto.imageType}`,
      coordinate: { latitude: placementPhoto.location?.lat, longitude: placementPhoto.location?.lng },
      zIndex: 20,
      variant: 'photo',
      iconBackgroundImage: placementPhoto?.imageUrl ?? undefined,
    } as IMapMarkerProps

    markers.push(placementPhotoMarker)
  }

  if (proofOfServicePhotos?.length && userAuthed) {
    const podMarkers = proofOfServicePhotos
      ?.filter(({ location }) => location?.lat && location?.lng)
      ?.map(({ location, url }: ProofOfServicePhoto, idx) => {
        return {
          key: `marker_proofOfServicePhoto_${idx}`,
          coordinate: { latitude: location!.lat as number, longitude: location!.lng as number },
          zIndex: 30,
          variant: 'package',
          iconBackgroundImage: url ?? undefined,
        }
      })

    markers = [...markers, ...podMarkers]
  }

  return markers
}

// Calculate center and delta based on all marker coordinates
const calculateRegion = async (
  markerPropsArray: Pick<IMapMarkerProps, 'coordinate'>[],
  viewRatio: number,
  bottomPaddingRatio?: number,
  address?: Address | undefined
) => {
  const [minLat, maxLat, minLng, maxLng] = markerPropsArray.reduce(
    ([_minLat, _maxLat, _minLng, _maxLng], marker) => {
      const { latitude: lat, longitude: lng } = marker.coordinate
      return [
        _minLat ? (lat < _minLat ? lat : _minLat) : lat,
        _maxLat ? (lat > _maxLat ? lat : _maxLat) : lat,
        _minLng ? (lng < _minLng ? lng : _minLng) : lng,
        _maxLng ? (lng > _maxLng ? lng : _maxLng) : lng,
      ]
    },
    [
      undefined as number | undefined,
      undefined as number | undefined,
      undefined as number | undefined,
      undefined as number | undefined,
    ]
  )

  const [lat, lng, deltaLat, deltaLng] =
    maxLat && minLat && maxLng && minLng
      ? [
          (maxLat + minLat) / 2, // Calculate center lat
          (maxLng + minLng) / 2, // Calculate center lng
          (maxLat - minLat) * 2, // Caculate diff lat and multiply by two to add spacing around markers
          (maxLng - minLng) * 2, // Caculate diff ln and multiply by two to add spacing around markers
        ]
      : address
      ? await getRegionFromAddress(address)
      : []

  const deltaLattitude = deltaLat ? (deltaLat < MAP_MIN_DELTA ? MAP_MIN_DELTA : deltaLat) : MAP_MIN_DELTA
  const deltaLongitude = deltaLng ? (deltaLng < MAP_MIN_DELTA ? MAP_MIN_DELTA : deltaLng) : MAP_MIN_DELTA

  const verticalDelta =
    deltaLattitude * viewRatio > deltaLongitude ? deltaLattitude : (deltaLongitude / viewRatio) * 0.6

  // Adjust lattitude for component overlay
  return [
    lat ? (bottomPaddingRatio && verticalDelta ? lat - verticalDelta * bottomPaddingRatio : lat) : undefined,
    lng,
    deltaLattitude,
    deltaLongitude,
  ]
}

const embeddedKey = process.env.REACT_APP_SMARTY_EMBEDDED_KEY

const getRegionFromAddress = async (address: Address) => {
  try {
    const endpoint = 'https://us-zipcode.api.smarty.com/lookup'
    let params = [`key=${embeddedKey}`, 'max_results=1']
    let coordinateDelta = 1
    if (address?.zipCode) {
      params.push(`zipcode=${address.zipCode}`)
      coordinateDelta = MAP_ZIP_DELTA
    } else if (address?.state && address?.city) {
      params.push(`state=${address.state}`)
      params.push(`city=${address.city}`)
      coordinateDelta = MAP_CITY_DELTA
    } else {
      return [undefined, undefined, undefined, undefined]
    }
    const smartyResponseData = await (
      await fetch(`${endpoint}?${params.join('&')}`, {
        headers: { Referer: 'www.shipveho.com' },
      })
    ).json()
    const zipCodeResult = smartyResponseData?.[0]?.zipcodes?.[0]
    return zipCodeResult?.latitude && zipCodeResult?.longitude
      ? [zipCodeResult.latitude, zipCodeResult.longitude, coordinateDelta, coordinateDelta]
      : [undefined, undefined, undefined, undefined]
  } catch (err) {
    logError('Error fetching address region data', { error: err })
    return [undefined, undefined, undefined, undefined]
  }
}
